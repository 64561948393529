<template>
  <div class="list-container">
    <div class="search-top">
      <el-form :model="orderForm" ref="orderForm" class="order-search-form">
        <el-form-item class="search-input" label="">
          <el-input
            class="search-input"
            v-model="orderForm.searchVal"
            placeholder="输入商品名称/订单编号搜索"
          ></el-input>
        </el-form-item>
        <el-form-item class="search-date-box" label="下单日期">
          <el-date-picker
            class="range-box"
            v-model="orderForm.createDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          class="search-btn"
          type="primary"
          @click="searchOrder('orderForm')"
          >搜索</el-button
        >
      </el-form>
    </div>
    <div class="list-content">
      <div class="query-item">
        <div class="order-detail common-head-text">订单详情</div>
        <div class="order-from common-head-text">订单来源</div>
        <div class="order-status common-head-text">订单状态</div>
        <div class="operate common-head-text">操作</div>
      </div>
      <div v-loading="orderLoading" class="list-table">
        <ul class="ul-cont" v-if="orderList.length > 0">
          <li
            class="li-cont"
            v-for="(item, index) of orderList"
            :key="item.id + '_' + index"
          >
            <div class="order_item_container">
              <div class="order_content">
                <div class="order_detail_content normal_box">
                  <div class="gray_head">
                    <div class="order_time">{{ item.createTime }}</div>
                    <div class="order_code_box">
                      <a class="order_code" @click="navToOrderAct(item)"
                        >订单号：{{ item.id }}</a
                      >
                      <a class="bank-card-copy">
                        <span
                          :data-clipboard-text="item.id"
                          @click="onCopyText('el-icon-document-copy')"
                          class="el-icon-document-copy"
                        ></span
                      ></a>
                    </div>
                  </div>
                  <div class="detail_box">
                    <div v-if="false" class="shop_img">
                      <img
                        src="https://s.yyjzt.com/jzt-item-web/19000101/EJA001033C/EJA001033C.JPG"
                        alt=""
                      />
                    </div>
                    <div class="order_info">
                      <span>实付金额：¥{{ item.payMoney }}</span>
                      <span
                        >支付方式：{{
                          item.feeType == 1 ? "线下支付" : "在线支付"
                        }}</span
                      >
                      <span v-if="false"
                        >共{{ item.saleOrderItemCount }}件商品</span
                      >
                    </div>
                  </div>
                </div>
                <div class="order_source_content normal_box">
                  <div class="gray_head">
                    <div class="store_name_info">
                      <span>
                        <div
                          role="tooltip"
                          id="el-popover-1371"
                          aria-hidden="true"
                          class="el-popover el-popper el-popover--plain"
                          tabindex="0"
                          style="display: none"
                        >
                          {{$configOptions.appName}}
                        </div>
                        <span class="el-popover__reference-wrapper"
                          ><span
                            class="store_name el-popover__reference"
                            aria-describedby="el-popover-1371"
                            tabindex="0"
                            >{{$configOptions.appName}}{{ item.sourceName }}端</span
                          ></span
                        >
                      </span>
                    </div>
                  </div>
                  <div class="detail_box">
                    <div class="order_source">线上订单</div>
                  </div>
                </div>
              </div>
              <div class="order_status_content">
                <div class="order_status_inner">
                  <div
                    class="order_status_text common"
                    style="color: rgb(255, 138, 0)"
                  >
                    {{ item.orderStatusName }}
                  </div>
                  <!---->
                  <!-- <div class="lg_btn">
                    <div @click="navToOrderAct(item)" class="nav-to-detail">查看详情</div> <span class="ls"></span>
                    <div><span class="el-popover__reference-wrapper"><span class="el-popover__reference"
                          aria-describedby="el-popover-1423" tabindex="0">查看物流</span></span>
                    </div>
                  </div> -->
                  <!-- <div class="common_btn">
                    下载报告单
                  </div>
                  <div class="common_btn download-style">
                    下载首营资料
                  </div> -->
                </div>
              </div>
              <div class="order_operate_content">
                <div class="order_operate_box">
                  <div class="common">
                    <button
                      v-if="item.nextFlow === 'confirm'"
                      @click.stop="confirmOrderClick(item)"
                      type="button"
                      class="el-button operate-common-btn btn-cont-f"
                    >
                      <span> 确认订单 </span>
                    </button>

                    <button
                      v-if="item.nextFlow === 'pay'"
                      @click.stop="payOrderClick(item)"
                      type="button"
                      class="el-button operate-common-btn btn-cont-f"
                    >
                      <span> 支付订单 </span>
                    </button>
                  </div>

                  <div
                    v-if="item.optCancelFlag === '1'"
                    @click.stop="cencalOrder(item)"
                    class="operate_common_btn"
                  >
                    取消订单
                  </div>
                  <div @click="navToOrderAct(item)" class="operate_common_btn">
                    查看详情
                  </div>
                  <!-- <div class="operate_common_btn">再次购买</div> -->
                  <!-- <div class="operate_common_btn">申请售后</div>
                  <div class="operate_common_btn">
                    评价
                  </div> -->
                </div>
              </div>
            </div>
            <div class="order-remark">
              <el-alert
                :closable="false"
                :title="`订单备注：${item.remark}`"
                type="warning"
              ></el-alert>
            </div>
          </li>
        </ul>
        <div v-else>
          <div class="dataNone">
            <div class="dataImg orderNoneImg"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-bottom">
      <el-pagination
        background
        :hide-on-single-page="true"
        v-if="totalCount > 5"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction, postAction } from "../../api/manage";

export default {
  name: "OrderRight",
  data() {
    return {
      orderForm: {
        searchVal: "", // 商品名称和订单编号
        createDate: "", //
      },
      pageSize: 5, // 每页显示的条数
      pageNo: 1, // 请求多少页
      totalCount: 1, // 请求多少页
      pickerOptions: {
        // 日期框快捷键操作参数
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      orderStatus: 0, // 订单状态：0：全部订单 1：待配货 2：待处理 3：已完成 4：已取消
      orderList: [],
      orderLoading: false,
    };
  },
  computed: {
    ...mapGetters(["pendingOrderCount"]),
  },
  created() {
    let query = this.$route.query;
    if (query && query !== "{}") {
      this.orderStatus = parseInt(query.state);
    }
    this.getOrderListData();
  },
  methods: {
    /**
     * @author: CaoXiaoChuan
     * @description: 订单表格信息跳转
     * @param {*} item 订单信息
     */
    navToOrderAct(item) {
      console.log("当前点击订单的数据为：", item);
      if (item.nextFlow === "confirm") {
        this.$router.push({path: 'confirmOrder', query: {saleOrderId: item.id}})
      } else {
        let text = this.$router.resolve({ name: 'orderDetail', query: { saleOrderId: item.id } });
        window.open(text.href, '_blank')
      }
    },
    /**
     * @author: CaoXiaoChuan
     * @description: 订单列表确认订单操作
     * @param {*} item 订单信息
     */
    confirmOrderClick(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否确认该笔订单?", "提示", {
        confirmButtonText: "确认订单",
        cancelButtonText: "取消操作",
        type: "info",
      }).then(() => {
        const url = "/saleOrder/saleOrder/confirmSaleOrder";
        let data = {
          saleOrderId: saleOrderId,
        };
        postAction(url, data, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单确认成功!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            that.getOrderListData();
          }
        });
      });
    },
    /**
     * @author: CaoXiaoChuan
     * @description: 跳转支付订单页面
     * @param {*} item
     */
    payOrderClick(item) {
      this.$router.push({
        name: "orderPay",
        query: { payOrderId: item.payOrderId, saleOrderId: item.id },
      });
    },
    auditStatus(auditStatus) {
      if (auditStatus === 1) {
        return "待审批";
      } else if (auditStatus === 2) {
        return "已通过";
      } else if (auditStatus === 3) {
        return "已驳回";
      }
    },
    cencalOrder(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否取消该笔订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const url =
          "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
        postAction(url, null, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单取消成功",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.getOrderListData();
          }
        });
      });
    },
    processingOrders(item) {
      const feeType = item.feeType;
      const saleOrderId = item.id;
      const payOrderId = item.payOrderId;
      const status = item.status;
      if (feeType === 2 && status === 2) {
        this.$router.push({
          name: "pay",
          query: { saleOrderId: saleOrderId, payOrderId: payOrderId },
        });
      } else {
        this.$router.push({
          name: "createOrder",
          query: { saleOrderId: saleOrderId },
        });
      }
    },
    orderStatusText() {
      let orderStatus = this.orderStatus;
      if (orderStatus == 1) {
        return "待配货";
      } else if (orderStatus == 2) {
        return "待处理";
      } else if (orderStatus == 3) {
        return "已完成";
      } else if (orderStatus == 4) {
        return "已取消";
      }
    },
    changeOrderStatus(num) {
      this.$emit("changeOrderStatus", num);
    },
    ceshiClass(index) {
      switch (index) {
        case 0:
          return "ytk";
        case 1:
          return "ybh";
        case 2:
          return "dhp";
      }
    },
    searchOrder(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let orderForm = this.orderForm;
          console.log("orderForm+++++++++++", orderForm);
          // 处理客户查询日期格式
          if (orderForm.createDate && orderForm.createDate.length > 0) {
            this.orderForm.dateStart = `${orderForm.createDate[0]} 00:00:00`;
            this.orderForm.dateEnd = `${orderForm.createDate[1]} 23:59:59`;
          } else {
            delete this.orderForm.dateStart;
            delete this.orderForm.dateEnd;
          }
          // 处理客户搜索信息
          orderForm.searchVal = orderForm.searchVal.replace(
            /(^\s*)|(\s*$)/g,
            ""
          );
          _this.getOrderListData();
        } else {
          return false;
        }
      });
    },
    getOrderListData() {
      let _this = this;
      this.orderLoading = true;
      let state = this.orderStatus;
      // 订单编号首尾去空格(防止用户复制粘贴多复制了空白);
      let orderForm = this.orderForm;
      // 分页数据，订单状态
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let orderStatus = parseInt(state) - 1;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        orderStatus: orderStatus,
        ...orderForm,
      };
      // 删除订单日期查询数据
      delete data.createDate;
      if (orderStatus == -1) {
        delete data.orderStatus;
      }
      console.log("订单搜索的查询条件：", data);
      let url = "/saleOrder/saleOrder/list";
      getAction(url, data).then((res) => {
        _this.orderLoading = false;
        if (res.code == 200 && res.success) {
          let resData = res.result;
          _this.orderList = resData.records;
          _this.pageNo = resData.current;
          _this.totalCount = resData.total;
        }
      });
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getOrderListData();
    },
  },
  watch: {
    $route(to, form) {
      let routerName = to.name;
      let query = to.query;
      if (routerName === "orderList") {
        let state = parseInt(query.state);
        this.orderStatus = state;
        this.pageNo = 1;
        this.getOrderListData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/order/list";
.dataNone {
  position: relative;
  width: 1060px;
}

.title-list {
  span {
    padding: 2px 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    font-size: 12px;
    margin-right: 5px;
    background: #ea5506;
  }
}
</style>
