<template>
  <el-dialog
    :visible.sync="visible"
    width="545px"
    class="modal-box"
    footer="null"
    center
  >
    <div class="logistic-data">
      <div class="receive-info">
        <p class="title">
          退货信息：<i
            :data-clipboard-text="`${companyConfigData.returnOrderLogisticsUser},${companyConfigData.returnOrderLogisticsUserPhone},${companyConfigData.returnOrderLogisticsAddress}`"
            @click="onCopyText('copy-refund-address')"
            title="一键复制退货信息"
            class="el-icon-document-copy copy-refund-address"
          ></i>
        </p>
        <p>收货人：{{ companyConfigData.returnOrderLogisticsUser }}</p>
        <p>联系电话：{{ companyConfigData.returnOrderLogisticsUserPhone }}</p>
        <p>退货地址：{{ companyConfigData.returnOrderLogisticsAddress }}</p>
      </div>
      <div class="operate-btn">
        <el-button
          :data-clipboard-text="`${companyConfigData.returnOrderLogisticsUser},${companyConfigData.returnOrderLogisticsUserPhone},${companyConfigData.returnOrderLogisticsAddress}`"
          @click="onCopyText('copy-refund-address')"
          class="submit-btn copy-refund-address"
          >一键复制退货信息</el-button
        >
      </div>
      <!-- <el-select
        v-model="logisticsTypeName"
        placeholder="请选择物流公司"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
    </div>
  </el-dialog>
</template>

<script>
import { postAction } from "../../api/manage";

export default {
  props: ["refundId"],
  data() {
    return {
      logisticsCode: "",
      visible: false,
      logisticsTypeName: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      companyConfigData: {},
    };
  },
  created() {
    let companyConfigData = this.$ls.get("companyConfigData");
    this.companyConfigData = companyConfigData;
    console.log("获取当前公司配置参数", companyConfigData);
  },
  methods: {
    async submitLogisticCode() {
      let id = this.refundId;
      let logisticsCode = this.logisticsCode;
      let logisticsTypeName = this.logisticsTypeName;
      console.log("logisticsCode", logisticsCode);
      console.log("logisticsTypeName", logisticsTypeName);
      if (!logisticsCode || logisticsCode === "") {
        this.$message.error("请填写物流单号");
        return false;
      }
      if (!logisticsTypeName || logisticsTypeName === "") {
        this.$message.error("请填写物流公司名称");
        return false;
      }
      let url = "saleOrder/saleReturnOrder/updateLogistics";
      let data = {
        id: id,
        logisticsCode: logisticsCode,
        logisticsTypeName: logisticsTypeName,
      };
      let res = await postAction(url, data);
      console.log("填写物流单返回的数据为", res);
      if (res.code === 200 && res.success) {
        this.$message.success("已成功回填物流单号，等待商家验收！");
        this.visible = false;
        this.$emit("ok");
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
::v-deep .el-dialog__close {
  font-size: 20px !important;
  &:hover {
    color: $act-color;
  }
}
.logistic-data {
  .el-input-group {
    margin-bottom: 15px;
    ::v-deep .el-input__inner {
      border-left: none;
      font-size: 14px;
      &:focus {
        border-color: #ccc;
      }
    }
    ::v-deep .el-input-group__prepend {
      background-color: #ffffff;
      color: #585858;
      font-size: 14px;
      border-radius: 0;
      border-color: #ccc;
      i {
        margin-right: 5px;
        color: $act-color;
        font-size: 14px;
        font-style: normal;
      }
    }
  }
}
.receive-info {
  margin-top: 20px;
  p {
    font-size: 13px;
    color: #585858;
    line-height: 25px;
    height: 25px;
    color: #333;
    &.title {
      color: #333;
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 10px;
      i {
        cursor: pointer;
        font-size: 16px;
        &:hover {
          color: $act-color;
        }
      }
    }
  }
}
.operate-btn {
  text-align: center;
  .submit-btn {
    width: 160px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    margin-top: 20px;
    border: none;
    background: #fd4016;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>