<template>
  <div class="IntergralContent">
    <div class="my content">
      <div class="content_box clear">
        <div class="my_left">
          <div class="userContainer">
            <div class="userIcon">
              <img src="~@/assets/images/IntegralShop/userIcon.png" alt="用户图标" />
              <p>
                {{ userData.name }}
              </p>
            </div>
            <div class="applicationList">
              <div class="myIntegral">
                <span class="integralIcon"></span>
                <p class="title">我的积分</p>
                <p class="integralNumber">{{ userIntegral }}</p>
              </div>
              <div class="mySignIn" @click="changeNavType">
                <span class="signInIcon"></span>
                <p class="title">我的签到</p>
                <span class="arrowRight"> &gt; </span>
              </div>
              <div class="myGiftClassify">
                <div>
                  <span class="giftIcon"></span>
                  <p class="title" style="width: 150px">礼品分类<span @click="screenGiftList" class="convertibleGift">({{
                    convertibleGiftFlag == 1
                    ? '全部礼品' : '可兑换礼品'
                  }})</span>
                  </p>
                </div>
                <div class="classifyList">
                  <span v-if="giftList.length > 0" @click="jump(0)">礼品兑换</span>
                  <span v-if="productList.length > 0" @click="jump(1)">药品兑换</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ban2">
          <div class="banner">
            <ul class="img">
              <li>
                <a href="javascript:;">
                  <img v-if="bannerUrl && bannerUrl !== ''" :src="bannerUrl" alt="" />
                  <!-- <img
                    v-else
                    src="@/assets/images/IntegralShop/jifenbanner.png"
                /> -->
                </a>
              </li>
            </ul>
            <ul class="num"></ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="giftList.length > 0" class="common content floorTitle" id="lpdh">
      <div class="content_box" style="height: auto">
        <div class="content_title">
          <span>礼品列表</span>
        </div>
        <ul class="common_ul clearfix">
          <li class="fl" v-for="(item, index) of giftList" :key="'giftKey' + index">
            <div class="img_box giftBox">
              <router-link :to="{ name: 'giftDetail', query: { id: item.id } }" href="javascript:;" :title="item.name">
                <img :src="giftImgSrc(item)" />
              </router-link>
            </div>
            <div class="xx">
              <p class="text_overflow">
                {{ item.name }}
              </p>
              <div class="priceBox clearfix">
                <p class="jfText">
                  {{ item.integral }}积分
                </p>
                <p class="jiage">
                  市场参考价：<span>￥{{ item.referencePrice }}</span>
                </p>
              </div>
              <p class="jiage amountBox">
                已兑换：{{ item.exchangeAmount }}
                <i v-if="item.limitAmount && item.limitAmount > 0">限兑数量：{{ item.limitAmount }}</i>
              </p>
              <div v-if="item.remainAmount > 0" class="jf clearfix">
                <div class="jiajian fl">
                  <div class="jia" @click="add(item)">+</div>
                  <input class="giftNumberInput" :value="item.jionAmount"
                    onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');" @change="changeAmount(item, $event)"
                    @keyup.enter="exchangeGift(item)" type="text" />
                  <div class="jian" @click="less(item)">-</div>
                </div>
                <a v-if="item.limitAmount && (item.limitAmount <= item.exchangeAmount)" class="fr duihuanA"
                  style="color: #666" href="javascript:;">兑换告罄</a>
                <a v-else class="fr duihuanA" @click="exchangeGift(item)" href="javascript:;">立即兑换</a>
              </div>
              <div v-else class="jf">
                <span style="font-size: 16px; color: #666">已售罄</span>
              </div>
            </div>
          </li>
        </ul>
        <div style="clear: both"></div>
      </div>
    </div>
    <div v-if="productList.length > 0" class="common content floorTitle" id="ypdh">
      <div class="content_box" style="height: auto">
        <div class="content_title">
          <span>药品列表</span>
        </div>
        <ul class="common_ul clearfix">
          <li class="fl" v-for="(item, index) of productList" :key="'giftKey' + index">
            <div class="img_box giftBox">
              <router-link v-if="item.productId && item.productId !== ''" :to="{
                name: 'productDetail',
                query: { productId: item.productId },
              }" :title="item.name" target="_blank">
                <img :src="giftImgSrc(item)" />
              </router-link>
              <a v-else href="javascript:;">
                <img :src="giftImgSrc(item)" />
              </a>
            </div>
            <div class="xx">
              <p class="text_overflow">
                {{ item.name }}
              </p>
              <div class="priceBox clearfix">
                <p class="jfText">
                  {{ item.integral }}积分
                </p>
                <p class="jiage">
                  市场参考价：<span>￥{{ item.referencePrice }}</span>
                </p>
              </div>
              <p class="jiage amountBox">
                已兑换：{{ item.exchangeAmount }}
                <i v-if="item.limitAmount && item.limitAmount > 0">限兑数量：{{ item.limitAmount }}</i>
              </p>
              <div v-if="item.remainAmount > 0" class="jf clearfix">
                <div class="jiajian fl">
                  <div class="jia" @click="add(item)">+</div>
                  <input class="giftNumberInput" :value="item.jionAmount"
                    onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');" @change="changeAmount(item, $event)"
                    @keyup.enter="exchangeGift(item)" type="text" />
                  <div class="jian" @click="less(item)">-</div>
                </div>
                <a v-if="item.limitAmount && (item.limitAmount <= item.exchangeAmount)" class="fr duihuanA"
                  style="color: #666" href="javascript:;">兑换告罄</a>
                <a v-else class="fr duihuanA" @click="exchangeGift(item)" href="javascript:;">立即兑换</a>
              </div>
              <div v-else class="jf">
                <span style="font-size: 16px; color: #666">已售罄</span>
              </div>
            </div>
          </li>
        </ul>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { accMuls } from "@/assets/js/common/common";
import { getUserInfo } from "../../assets/js/common/common";
import { getAction } from "../../api/manage";

export default {
  name: "IntegralShop",
  props: ["userIntegral", "userData", "bannerUrl"],
  data() {
    return {
      productList: [],
      giftList: [],
      convertibleGiftFlag: 0
    };
  },
  created() {
    this.getIntegralGiftList();
  },
  methods: {
    // 筛选礼品列表
    screenGiftList() {
      let convertibleGiftFlag = this.convertibleGiftFlag
      if (convertibleGiftFlag == 1) {
        this.convertibleGiftFlag = 0
      } else {
        this.convertibleGiftFlag = 1
      }
      this.getIntegralGiftList()
    },
    changeNavType() {
      this.$emit("changeNavType", 3);
    },
    // 产品礼品图片
    giftImgSrc(item) {
      if (item.picUrl && item.picUrl !== "") {
        let picUrlArr = item.picUrl.split(",");
        let picUrl = picUrlArr[0];
        if (picUrl) {
          return `${picUrl}`;
        }
      }
    },
    /**
     * 锚点连接平滑滚动
     **/
    jump(index) {
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".floorTitle");
      let total = jump[index].offsetTop;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }

      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }

      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
    // 获取用户的积礼品列表的数据
    getIntegralGiftList() {
      let _this = this;
      let convertibleGiftFlag = this.convertibleGiftFlag
      let data = {
        convertibleGiftFlag: convertibleGiftFlag
      }
      let url = "/integralMall/integralGift/list";
      getAction(url, data).then((res) => {
        console.log("积分商城礼品数据为++++++", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          if (resData.giftList && resData.giftList.length > 0) {
            _this.giftList = resData.giftList;
          }
          if (resData.productList && resData.productList.length > 0) {
            _this.productList = resData.productList;
          }
        }
      });
    },
    // 数据添加
    add(item) {
      let beforeAmount = 0;
      let amount = item.limitAmount; // 活动礼品的数量
      let jionAmount = item.jionAmount; // 用户的添加数量
      if (jionAmount) {
        beforeAmount = jionAmount;
      }
      if (beforeAmount < amount) {
        jionAmount = beforeAmount + 1;
      }
      this.$set(item, "jionAmount", jionAmount);
      item.jionAmount = jionAmount;
    },
    less(item) {
      let beforeAmount = 0;
      let amount = parseInt(item.limitAmount); // 活动礼品的数量
      let jionAmount = item.jionAmount; // 用户的添加数量
      if (jionAmount) {
        beforeAmount = jionAmount;
      }
      if (beforeAmount <= amount && beforeAmount > 0) {
        jionAmount = beforeAmount - 1;
      } else if (beforeAmount <= 0) {
        jionAmount = 0;
      }
      this.$set(item, "jionAmount", jionAmount);
      item.jionAmount = jionAmount;
    },
    changeAmount(item, e) {
      let value = e.target.value;
      let amount = parseInt(item.limitAmount); // 活动礼品的数量
      if (value > 0 && value <= amount) {
        value = value;
      } else if (value > amount) {
        value = amount;
      } else if (value < 0) {
        value = 0;
      }
      this.$set(item, "jionAmount", value);
      item.jionAmount = value;
    },
    exchangeGift(item) {
      let _this = this;
      let integralGiftId = item.id; // 礼品的id
      let jionAmount = item.jionAmount; // 用户兑换的数量
      let integral = item.integral; // 当前礼品所对应的积分
      let limitAmount = item.limitAmount; // 当前限兑数量
      let amount = item.amount; // 当前总数量
      let userIntegral = this.userIntegral; // 用户总积分
      // 进行数量判断
      if (jionAmount > 0) {
        this.$confirm(
          `当前兑换的数量为${jionAmount},是否继续？`,
          "提示信息：",
          { type: "info" }
        )
          .then((res) => {
            // 前端进行用户积分兑换的验证
            let allJF = accMuls(jionAmount, integral);
            if (parseInt(userIntegral) < parseInt(allJF)) {
              _this.$alert("兑换礼品所用积分不足!", "提示信息:", {
                type: "error",
              });
            } else {
              if (limitAmount && limitAmount !== "") {
                if (limitAmount < jionAmount) {
                  jionAmount = limitAmount;
                }
              } else {
                if (jionAmount > amount) {
                  jionAmount = amount;
                }
              }
              let url = "/integralMall/integralGift/exchangeIntegralGift";
              let addData = {
                integralGiftId: integralGiftId,
                amount: jionAmount,
              };
              console.log("兑换传给后台的数据为++++++", addData);
              getAction(url, addData).then((res) => {
                console.log("兑换数量的数据为++++++", res);
                if (res.code === 200 && res.success) {
                  _this.$alert(res.message, "提示信息:", { type: "success" });
                  item.jionAmount = 0;
                  _this.getIntegralGiftList();
                  _this.$emit("getUserIntegral");
                } else {
                  _this.$alert(res.message, "提示信息:", { type: "error" });
                  item.jionAmount = 0;
                }
              });
            }
          })
          .catch((error) => { });
      } else {
        this.$alert("请输入正确的兑换数量！", "提示信息:", { type: "warning" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>@import "@/assets/styles/intergralCenter/intergralShop";</style>
