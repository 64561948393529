<template>
  <div class="rightNavContent" @click.stop :style="
    showCartStatus || ticketStatus || collectStatus || historyStatus
      ? 'right:0'
      : 'right:-250px'
  ">
    <div class="rightNavList">
      <ul>
        <li class="zhedieRight" title="折叠" v-if="
          showCartStatus || ticketStatus || collectStatus || historyStatus
        " @click="closeRightNav">
          <img src="@/assets/images/right/closeRightIcon.png" alt="折叠" />
        </li>
        <li class="rigthIcon kefuRight">
          <img src="~@/assets/images/right/rightKefuIcon.png" alt="联系客服" />
          <div class="tip">
            <a class="linkKefu" target="_blank" :href="`https://kefu.jiuqu817.com/chatIndex?kefu_id=${companyConfigData.customerServiceQq ? companyConfigData.customerServiceQq : 'kefu'}`">
            </a>
            <img src="~@/assets/images/right/kefuImg.png" alt="联系客服" />
            <span class="kefuTel">{{companyConfigData.customerServiceTel}}</span>
          </div>
        </li>
        <li class="messageBox">
          <router-link to="/messageList">
            <img src="~@/assets/images/right/rightMessageIcon.png" alt="消息" />
            <p>消息</p>
            <span v-if="unreadMsgNumAll.sumCount && unreadMsgNumAll.sumCount > 0" class="cartNum">{{
                unreadMsgNumAll.sumCount
            }}</span>
            <div v-if="unreadMsgNumAll.sumCount && unreadMsgNumAll.sumCount > 0" class="tip tipText">
              <p class="msgText">您有 <span>{{
              
              }}</span> 条未读消息</p>
            </div>
          </router-link>
        </li>
        <li v-if="showShopping" class="rigthIcon cartRightBox" @click="showTypeStatus('showCartStatus')">
          <img class="cartImg" src="@/assets/images/right/rightShopIcon.png" alt="购物车" />
          <span class="cartNum">{{ shoppingCartCount }}</span>
          <div class="tip">
            <img src="~@/assets/images/right/myShoppingCartIcon.png" alt="我的购物车" />
          </div>
        </li>
        <li v-else class="rigthIcon cartRightBox">
          <img class="cartImg" src="@/assets/images/right/rightShopIcon.png" alt="购物车" />
          <span class="cartNum">{{ shoppingCartCount }}</span>
          <div class="tip">
            <img src="~@/assets/images/right/myShoppingCartIcon.png" alt="我的购物车" />
          </div>
        </li>

        <li @click="gotoUrl('/myAccount')" class="cartRightBox centerRightBox">
          <img :src="`${$configOptions.resourcePath}/config/logo/logo-white.png`" alt="个人中心" />
          <p>个人中心</p>
        </li>
        <li @click="showTypeStatus('ticketStatus')" class="rigthIcon rightTicket">
          <img src="~@/assets/images/right/rightTicketIcon.png" alt="奖券列表" />
          <div class="tip">
            <img src="~@/assets/images/right/myTicketIcon.png" alt="奖券列表" />
          </div>
        </li>
        <li @click="showTypeStatus('historyStatus')" class="rigthIcon rightHistory">
          <img src="~@/assets/images/right/rightHistoryIcon.png" alt="历史采购" />
          <div class="tip">
            <img src="~@/assets/images/right/myHistoryIcon.png" alt="历史采购" />
          </div>
        </li>
        <li @click="showTypeStatus('collectStatus')" class="rigthIcon rightCollect">
          <img src="~@/assets/images/right/rightCollectIcon.png" alt="我的收藏" />
          <div class="tip">
            <img src="~@/assets/images/right/myCollect.png" alt="我的收藏" />
          </div>
        </li>
        <li v-if="false" class="downBox">
          <img src="@/assets/images/right/rightEcodeIcon.png" alt="APP下载" />
          <p class="apptext">APP</p>
          <p>下载</p>
          <div class="downImg">
            <img src="~@/assets/images/right/phone-ecode-right.png" alt="" />
          </div>
        </li>

        <li class="rigthIcon rightCallback">
          <router-link to="/feedback">
            <img src="~@/assets/images/right/rightBackIcon.png" alt="我要反馈" />
            <div class="tip">
              <img src="~@/assets/images/right/mybackIcon.png" alt="我要反馈" />
            </div>
          </router-link>
        </li>
      </ul>
      <a @click="backTop" class="goTop" href="javascript:;">
        <img src="@/assets/images/right/rightTopIcon.png" alt="返回顶部" />
      </a>
    </div>
    <!-- 购物车列表 -->
    <div v-if="showShopping && showCartStatus" class="shoppingCartList">
      <div class="cartHeader">
        <img src="@/assets/images/searchContent/cartIcon.png" alt="购物车" />
        购物车
      </div>
      <div v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.6)" class="cartProList">
        <el-scrollbar v-if="shoppingCartList.length > 0" class="productDataList">
          <div class="productDataBox" @click="gotoDetail(item)" v-for="(item, index) of shoppingCartList"
            :key="'Rightcart' + index">
            <img v-lazy="productImg(item)" class="productimg" />
            <div class="databox">
              <p class="name">
                <a href="javascript:;" :title="item.productName">
                  {{ item.productName }}
                </a>
              </p>
              <p :title="item.productFormat" class="format">
                {{ item.productFormat }}
              </p>
              <p class="manufactor">
                <a href="javascript:;" :title="item.productManufacturer">
                  {{ item.productManufacturer }}
                </a>
              </p>
              <div class="priceAndAmount shopAmoutBox">
                <span class="price">￥ {{ item.price ? item.price : "--/--" }}</span>
                &times;
                <span class="number">{{ item.amount }}</span>
              </div>
              <i title="移除该产品" @click.stop="removeProduct(item)" class="deletePro el-icon-delete"></i>
            </div>
          </div>
        </el-scrollbar>
        <div v-else class="datanone">
          <div class="dataimg shopNoneImg"></div>
        </div>
      </div>
      <div v-if="shoppingCartList.length > 0" class="cartBottom">
        <div class="cartData">
          <p>
            共计<span>{{ shoppingCartList.length }}</span>件
          </p>
          <p>
            总计：<span>￥{{ shopCartMoney }}</span>
          </p>
        </div>
        <div @click="gotoShoppingCart" class="createBtn">前往购物车</div>
      </div>

      <div v-else class="cartBottom">
        <div class="cartData">
          <p>购物车是空的哦！</p>
          <p>快去挑选商品吧！</p>
        </div>
        <div @click="gotoProduct" class="createBtn">挑选商品</div>
      </div>
    </div>
    <!-- 优惠券列表 -->
    <div v-else-if="ticketStatus" class="shoppingCartList">
      <div class="cartHeader">
        <img src="@/assets/images/center/ticket/ticketHeaderIcon.png" alt="优惠券" />
        优惠券
      </div>
      <div v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.6)" class="cartProList">
        <el-scrollbar class="ticketDataList">
          <div class="ticketDataBox" v-for="(item, index) of ticketDataList" :key="'Rightcart' + index">
            <div class="ticketLeft">
              <div v-if="item.ticketType == 1" class="price">
                <p class="money">{{ item.ticketDiscountMoney }}</p>
                <p class="typeText">元</p>
              </div>
              <div v-if="item.ticketType == 2" class="price">
                <p class="money">
                  {{ parseFloat((item.ticketDiscountMoney * 10).toPrecision(12)) }}
                </p>
                <p class="typeText">折</p>
              </div>
              <div class="ticketData">
                <p class="name">{{ item.ticketName }}</p>
                <p class="date">
                  仅{{ timeClear(item.startTime) }}-{{
                      timeClear(item.endTime)
                  }}使用
                </p>
              </div>
              <div class="detaill">{{ item.ticketDetail }}</div>
            </div>
            <div class="ticketRight">
              <a @click="useTicket(item)" v-if="item.ticketReceiveStatus == 1" class="sanzi" href="javascript:;">去使用</a>
              <a v-if="item.ticketReceiveStatus == 0" class="sanzi" href="javascript:;">已领完</a>
              <a v-else @click="lingquTicket(item)" href="javascript:;">立即领取</a>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- 历史采购 -->
    <div v-else-if="historyStatus" class="shoppingCartList">
      <div class="cartHeader">
        <img src="~@/assets/images/right/historyTitleIcon.png" alt="历史采购" />
        历史采购
      </div>
      <div v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.6)" class="cartProList">
        <el-scrollbar v-if="historyList.length > 0" class="productDataList">
          <div class="productDataBox" @click="gotoDetail(item)" v-for="(item, index) of historyList"
            :key="'Rightcart' + index">
            <img v-lazy="productImg(item)" class="productimg" />
            <div class="databox">
              <p class="name">
                <a href="javascript:;">
                  {{ item.productName }}
                </a>
              </p>
              <p :title="item.format" class="format">
                {{ item.format }}
              </p>
              <p class="manufactor">
                <a href="javascript:;" title="item.manufacturer">
                  {{ item.manufacturer }}
                </a>
              </p>
              <div class="priceAndAmount">
                <span class="price">￥ {{ item.price ? item.price : "--/--" }}</span>
              </div>

              <span style="display: block; right: 0" class="jionText">已购：{{ item.orderCount }}次</span>
            </div>
          </div>
        </el-scrollbar>
        <div v-else class="datanone">
          <div class="dataimg"></div>
        </div>
      </div>
      <div v-if="historyList.length > 0" class="collectBottom">
        <span @click="gotoUrl('/bestShopping')" class="lookMoreBtn">查看历史采购</span>
      </div>

      <div v-else class="cartBottom">
        <div class="cartData">
          <p>采购记录是空的哦！</p>
          <p>快去挑选商品下单吧！</p>
        </div>
        <div @click="gotoProduct" class="createBtn">挑选商品</div>
      </div>
    </div>
    <!-- 我的收藏 -->
    <div v-else-if="collectStatus" class="shoppingCartList">
      <div class="cartHeader">
        <img src="~@/assets/images/right/collectTitleIcon.png" alt="我的收藏" />
        我的收藏
      </div>
      <div v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.6)" class="cartProList">
        <el-scrollbar v-if="collectList.length > 0" class="productDataList">
          <div class="productDataBox" @click="gotoDetail(item)" v-for="(item, index) of collectList"
            :key="'Rightcart' + index">
            <img v-lazy="productImg2(item)" class="productimg" />
            <div class="databox">
              <p class="name">
                <a href="javascript:;" :title="item.name">
                  {{ item.name }}
                </a>
              </p>
              <p :title="item.format" class="format">
                {{ item.format }}
              </p>
              <p class="manufactor">
                <a href="javascript:;" :title="item.manufacturer">
                  {{ item.manufacturer }}
                </a>
              </p>
              <div class="priceAndAmount">
                <span class="price">￥ {{ item.showPrice ? item.showPrice : "--/--" }}</span>
              </div>
              <span class="jionText" @click.stop="jionShopCart(item)">加入购物车</span>
              <i title="移除该产品" @click.stop="removeCollectProduct(item)" class="deletePro el-icon-delete"></i>
            </div>
          </div>
        </el-scrollbar>
        <div v-else class="datanone">
          <div class="dataimg"></div>
        </div>
      </div>
      <div v-if="collectList.length > 0" class="collectBottom">
        <span @click="gotoUrl('/collect')" class="lookMoreBtn">查看全部收藏</span>
      </div>

      <div v-else class="cartBottom">
        <div class="cartData">
          <p>收藏夹是空的哦！</p>
          <p>快去挑选商品吧！</p>
        </div>
        <div @click="gotoProduct" class="createBtn">挑选商品</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteAction, getAction, postAction } from "../../api/manage";
import { accAdd, getUserInfo } from "../../assets/js/common/common";
export default {
  name: "RightNav",
  data() {
    return {
      showCartStatus: false,
      ticketStatus: false,
      collectStatus: false,
      historyStatus: false,
      showShopping: true, // 购物车侧边栏展示状态
      btnFlag: false,
      listLoading: false, // 列表加载动画
      shoppingCartList: [], // 购物车列表数据
      ticketDataList: [], // 优惠券列表数据
      collectList: [], // 收藏夹列表
      historyList: [], // 历史采购列表
    };
  },
  // vue的两个生命钩子，这里不多解释。
  // window对象，所有浏览器都支持window对象。它表示浏览器窗口，监听滚动事件
  created() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  unmounted() {
    window.removeEventListener("scroll", () => {
      this.scrollToTop();
    });
  },
  computed: {
    ...mapGetters(["shoppingCartCount", "collectUploadStatus", "unreadMsgNumAll","companyConfigData"]),
    shopCartMoney() {
      let shoppingCartList = this.shoppingCartList;
      let allMoney = 0;
      for (let key of shoppingCartList) {
        allMoney = accAdd(allMoney, key.productMoney);
      }
      return allMoney;
    },
  },
  methods: {

    // 去使用优惠券
    useTicket(item) {
      let astrictProductFlag = item.astrictProductFlag
      this.showTypeStatus()
      if (astrictProductFlag !== 0) {
        let discountTicketId = item.ticketId;
        this.$router.push({
          name: 'productList',
          query: {
            discountTicketId: discountTicketId
          }
        })
      } else {
        this.$router.push({
          name: 'productList'
        })
      }
    },
    // 添加购物车
    async jionShopCart(itemF) {
      let _this = this;
      let inventoryList = itemF.inventoryList;
      if (inventoryList && inventoryList.length > 0) {
        let item = inventoryList[0];
        let addPrice = item.price;
        let amount = 1;
        let uid = item.uid;
        let addShopData = {
          addPrice: addPrice,
          amount: amount,
          uid: uid,
        };
        if (item.productPriceStrategyId && item.productPriceStrategyId !== "") {
          addShopData.productPriceStrategyId = item.productPriceStrategyId;
        }
        if (
          itemF.productActivityPriceStrategyFlag &&
          itemF.productActivityPriceStrategyDetail &&
          itemF.productActivityPriceStrategyDetail.canSaleAmount > 0
        ) {
          addShopData.addPrice = itemF.productActivityPriceStrategyDetail.price;
          addShopData.uid =
            itemF.productActivityPriceStrategyDetail.productInventoryUid;
          addShopData.productActivityPriceStrategyId =
            itemF.productActivityPriceStrategyDetail.productActivityPriceStrategyId;
        }
        console.log("添加购物车的数据为++++++", addShopData);
        let addShopUrl = "/shopping/cart/add?getList=1";
        postAction(addShopUrl, addShopData).then((res) => {
          if (res.success && res.code == 200) {
            this.$message({
              message: "商品已添加到购物车！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            let shoppingCartCount = res.result.shoppingCartListcount;
            _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
          } else {
            this.$alert(res.message, "提示信息", {
              type: "error",
            });
          }
        });
      } else {
        this.$alert("该产品暂无库存", "提示信息", {
          type: "error",
        });
      }
    },
    // 前往指定位置
    gotoUrl(path) {
      this.showTypeStatus();
      this.$router.push(path);
    },
    // 获取我的收藏夹数据
    async getMyCollectList() {
      this.listLoading = true;
      const url = "product/getlistE";
      let data = {
        pageNo: 1,
        pageSize: 20,
        collectFlag: 1,
      };
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        this.listLoading = false;
        let collectList = res.result.pageData.records;
        console.log("获取收藏夹列表数据为++++++", collectList);
        this.collectList = collectList;
      }
    },
    changeTipStatus(optionName, status) {
      console.log("optionName+++++", optionName);
      this.showRightTip[optionName] = status;
      console.log("status+++++", status);
    },
    // 跳转购物车操作
    gotoOrderList() {
      this.showTypeStatus();
      this.$router.push({
        name: "orderList",
        query: {
          state: 3,
        },
      });
    },
    showTypeStatus(typeName) {
      let typeList = [
        "showCartStatus",
        "ticketStatus",
        "collectStatus",
        "historyStatus",
      ];
      if (typeName) {
        let nowStatus = this[typeName];
        if (!nowStatus) {
          for (let key of typeList) {
            if (key == typeName) {
              this[typeName] = true;
            } else {
              this[key] = false;
            }
          }
          if (typeName == "showCartStatus") {
            this.getShoppingCartList();
          } else if (typeName == "ticketStatus") {
            this.getTicketList();
          } else if (typeName == "collectStatus") {
            this.getMyCollectList();
          } else if (typeName == "historyStatus") {
            this.getMyHisttoryList();
          }
        } else {
          this[typeName] = false;
        }
      } else {
        for (let key of typeList) {
          this[key] = false;
        }
      }
    },
    // 获取历史采购数据
    async getMyHisttoryList() {
      let url = "historicalPurchase/list";
      let data = {
        pageNo: 1,
        pageSize: 20,
        orderCountFlag: 1
      };

      // // 指定时间内
      let endTime = this.getStartDate(1, 1);
      let startTime = this.getStartDate(1, 2);
      data.startTime = startTime;
      data.endTime = endTime;
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        this.historyList = res.result.records;
        console.log("获取历史采购列表的数据为++++++", this.historyList);
      }
    },
    getStartDate(type, returnType) {
      var dateTime = null;
      var nowDate = new Date();
      if (returnType == 1) {
        dateTime = nowDate.getTime();
      } else if (returnType == 2) {
        dateTime =
          nowDate.getTime() - parseInt(type) * 30 * 24 * 60 * 60 * 1000;
      }
      console.log(dateTime);
      var date = new Date(dateTime);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var curDate = date.getDate();
      var curHours = date.getHours();
      var curMinutes = date.getMinutes();
      var curSeconds = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
      }
      if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
      }
      return `${year}-${month}-${curDate} ${curHours}:${curMinutes}:${curSeconds}`;
    },
    //折叠右侧栏
    closeRightNav() {
      this.showTypeStatus();
    },
    // 加载购物车列表数据
    loadingShopCart() {
      let routerName = this.$route.name;
      if (routerName == "shoppingCart") {
        this.showShopping = false;
        return false;
      } else {
        this.showShopping = true;
      }
      let showCartStatus = this.showCartStatus; // 右侧购物车状态栏
      if (showCartStatus) {
        this.showTypeStatus();
      } else {
        this.showTypeStatus("showCartStatus");
        // 判断用户的登录状态
        let userInfo = getUserInfo();
        if (userInfo) {
          this.getShoppingCartList();
        } else {
          // 用户没有登录让用户跳转到登录界面
          this.$alert("请登录后操作!", "提示信息：");
          this.$router.push({ path: "/login" });
        }
      }
    },

    // 处理时间格式
    timeClear(time) {
      if (time && typeof time !== "undefined") {
        let timeArr = time.split(" ");
        let dateArr = timeArr[0].split("-");

        return `${dateArr[1]}.${dateArr[2]}`;
      }
    },

    lingquTicket(item) {
      let _this = this;
      let data = {
        id: item.id,
      };
      let url =
        "/discountTicket/discountTicketStrategyReceive/receiveDiscountTicket";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.getTicketList();
        }
      });
    },
    productImg(item) {
      if (item.productPic && item.productPic !== "") {
        let picUrlArr = item.productPic.split(",");
        const imgsArr = picUrlArr[0];
        const imgSrc = this.$imgPath + imgsArr;
        return imgSrc;
      } else {
        const imgSrc = "~@/assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    productImg2(item) {
      if (item.picUrl && item.picUrl !== "") {
        let picUrlArr = item.picUrl.split(",");
        const imgsArr = picUrlArr[0];
        const imgSrc = this.$imgPath + imgsArr;
        return imgSrc;
      } else {
        const imgSrc = "~@/assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    gotoDetail(item) {
      this.$router.push({
        name: "productDetail",
        query: { productId: item.productId },
      });
    },
    // 收藏夹移除产品
    removeCollectProduct(item) {
      let _this = this;
      let data = {
        productId: item.productId,
      };
      let url =
        "/customerCollectProduct/customerCollectProduct/cancelProductCollect";
      deleteAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.$message({
            message: "已取消收藏该产品",
            offset: 400,
            duration: 1000,
            type: "error",
          });
          this.getMyCollectList();
        }
      });
    },

    // 购物车移除产品
    removeProduct(item) {
      const _this = this;
      this.listLoading = true;
      const deleteUrl = "/shopping/cart/delete?getList=1";
      const feeType = this.feeType;
      const data = {
        id: item.id,
        getList: 1,
      };
      postAction(deleteUrl, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.listLoading = false;
          const resData = res.result;
          _this.shoppingCartList = resData.shoppingCartList;
          _this.$store.commit(
            "SHOPPING_CART_COUNT",
            resData.shoppingCartList.length
          );
        }
      });
    },
    getShoppingCartList() {
      this.listLoading = true;
      const _this = this;
      const url = "/shopping/cart/getList";
      let data = {};
      postAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.listLoading = false;
          const resData = res.result;
          _this.shoppingCartList = resData.shoppingCartList;
          _this.$store.commit(
            "SHOPPING_CART_COUNT",
            resData.shoppingCartList.length
          );
        }
      });
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    scrollToTop() {
      // 计算浏览器视口高度，当高度大于浏览器视口显示回顶部按钮，小于则隐藏
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      const h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      if (scrollTop > h) {
        this.btnFlag = true;
      } else {
        this.btnFlag = false;
      }
    },
    gotoShoppingCart() {
      let routerName = this.$route.name;
      if (routerName == "shoppingCart") {
        this.showCartStatus = false;
      } else {
        this.showCartStatus = false;
        this.$router.push("/shoppingCart");
      }
    },
    gotoProduct() {
      let routerName = this.$route.name;
      if (routerName == "productList") {
        this.showCartStatus = false;
      } else {
        this.showCartStatus = false;
        this.$router.push("/productList");
      }
    },

    // 获取奖券列表
    async getTicketList() {
      let _this = this;
      this.listLoading = true;
      let url =
        "/discountTicket/discountTicketStrategyReceive/getCanReceiveDiscountTicketlist";
      let res = await getAction(url);
      console.log("返回的优惠券列表的数据为++++++", res);
      if (res.code === 200 && res.success) {
        this.listLoading = false;
        let resData = res.result;
        _this.ticketDataList = resData;
      }
    },
  },
  mounted() {
    let that = this
    document.body.addEventListener('click', function() {
      that.closeRightNav()
    })
  },
  watch: {
    $route(to, from) {
      let routerName = to.name;
      if (routerName == "shoppingCart") {
        this.showShopping = false;
        this.showCartStatus = false;
        return false;
      } else {
        this.showShopping = true;
      }
    },
    shoppingCartCount() {
      this.getShoppingCartList()
    },
    collectUploadStatus(status) {
      this.getMyCollectList()
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/rightNav";
</style>