<template>
  <div class="order-detail-cont">
    <div class="container_box">
      <div class="detail-header">
        <div class="order-status">
          <div class="order-now-status">
            <img
              v-if="saleOrder.orderStatus == '1'"
              src="~@/assets/images/center/dph.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '2'"
              src="~@/assets/images/center/dcl.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '3'"
              src="~@/assets/images/center/ywc.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '-1'"
              src="~@/assets/images/center/shdd.png"
              :alt="saleOrder.orderStatusName"
            />
            <img
              v-if="saleOrder.orderStatus == '0'"
              src="~@/assets/images/center/qbdd.png"
              :alt="saleOrder.orderStatusName"
            />

            <span>{{ saleOrder.orderStatusName }}</span>
          </div>
          <p v-if="saleOrder.orderStatus == '1'">
            该订单处于待确认状态，请先进行订单确认！
          </p>
          <!-- <p>该订单包裹待配货，出库单号: FKLXSG03119894。</p> -->
        </div>
        <div class="order-header-info">
          <div class="card-header">
            <p class="order-code">
              订单号：<span>{{ saleOrder.id }}</span>
              <i
                :data-clipboard-text="saleOrder.id"
                @click="onCopyText('el-icon-document-copy')"
                class="el-icon-document-copy"
              ></i>
            </p>
            <p class="create-time">下单时间：{{ saleOrder.createTime }}</p>
          </div>
          <div class="card-cont">
            <div class="order-info">
              <div class="info-item-box">
                <p class="info-item">
                  <span class="info-label">支付方式:</span>
                  <span class="info-content">{{
                    saleOrder.feeType === 1 ? "线下支付" : "在线支付"
                  }}</span>
                </p>
                <p class="info-item">
                  <span class="info-label">创建时间:</span>
                  <span class="info-content">{{ saleOrder.createTime }}</span>
                </p>
                <p
                  v-if="saleOrder.payTime && saleOrder.payTime !== ''"
                  class="info-item"
                >
                  <span class="info-label">支付时间:</span>
                  <span class="info-content">{{ saleOrder.payTime }}</span>
                </p>
              </div>
              <div class="order-address info-item">
                <span class="info-label">配送地址:</span>
                <span v-if="saleOrder.logisticsAddress" class="info-content"
                  >{{ saleOrder.logisticsProvinceName
                  }}{{ saleOrder.logisticsCityName
                  }}{{ saleOrder.logisticsDistrictName
                  }}{{ saleOrder.logisticsAddress }}</span
                >
              </div>
            </div>
            <div class="logistics-info">
              <p class="logistics-info-title">物流信息</p>
              <el-tabs v-model="companyId" @tab-click="changeLogisticsStock">
                <el-tab-pane
                  v-for="(item, index) of companySaleOrderList"
                  :key="index"
                  :label="`${$configOptions.appName}-${item.companyName}`"
                  :name="item.companyId"
                >
                  <!-- 每个仓对应的几个物流数据 -->
                  <div
                    v-if="
                      item.logisticsVoList && item.logisticsVoList.length > 0
                    "
                  >
                    <div style="display: flex">
                      <div
                        class="logistics-list"
                        v-for="(itemC, indexC) of item.logisticsVoList"
                        :key="indexC"
                      >
                        <div
                          @click="changeLogisticsWaybillId(itemC)"
                          class="logistics-type-name"
                          :class="{
                            selected:
                              selectedLogisticsWaybillId ===
                              itemC.logisticsWaybillId,
                          }"
                        >
                          包裹{{ indexC + 1 }}
                        </div>
                      </div>
                    </div>
                    <!-- 选中的物流单数据 -->
                    <div v-if="selectedLogistics">
                      <div v-if="false" class="package-code">
                        <p>
                          物流单号：{{ selectedLogistics.logisticsWaybillId }}
                        </p>
                      </div>
                      <div class="logistics-info-header">
                        <div class="logistics-type">
                          <span class="logistics-company">{{
                            selectedLogistics.logisticsTypeName
                          }}</span>
                          <p class="line"></p>
                          <p class="logistics-company">
                            <span>物流单号：</span>
                            <span
                              >{{
                                selectedLogistics.logisticsWaybillId
                                  ? selectedLogistics.logisticsWaybillId
                                  : "暂无物流单号信息"
                              }}
                              <i
                                :data-clipboard-text="
                                  selectedLogistics.logisticsWaybillId
                                "
                                @click="onCopyText('logisticsWaybillId')"
                                class="el-icon-document-copy logisticsWaybillId"
                              ></i>
                            </span>
                          </p>
                        </div>
                        <p
                          @click="showLogisticsInfo(selectedLogistics)"
                          class="look-logistics-detail"
                        >
                          <span>查看详情</span>
                          <i class="el-icon-arrow-right"></i>
                        </p>
                      </div>
                      <div
                        v-if="
                          selectedLogistics.logisticsInfo &&
                          selectedLogistics.logisticsInfo.length > 0
                        "
                        class="logistics-node"
                      >
                        <i class="el-icon-success"></i>
                        <div class="node-info">
                          <p class="node-type">
                            <span class="node-status">{{
                              selectedLogistics.logisticsStatusName
                            }}</span>
                            <span>{{
                              selectedLogistics.logisticsInfo[
                                selectedLogistics.logisticsInfo.length - 1
                              ].key
                            }}</span>
                          </p>
                          <div
                            v-for="(itemCC, indexCC) of selectedLogistics
                              .logisticsInfo[
                              selectedLogistics.logisticsInfo.length - 1
                            ].val"
                            :key="indexCC"
                          >
                            <p>{{ itemCC }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <!-- 选中的物流单数据 -->
                    <div>
                      <div class="package-code">
                        <p class="tips">暂无物流信息数据，请联系客服查询！</p>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>

      <div class="stock-list">
        <div
          class="stock-content"
          v-for="(itemF, indexF) of companySaleOrderList"
          :key="indexF"
        >
          <!-- 商品列表 -->
          <div
            v-if="itemF.saleOrderItemList && itemF.saleOrderItemList.length > 0"
            class="store-info"
          >
            <div class="store-info-head">
              <div class="title">
                {{ $configOptions.appName }}-{{ itemF.companyName }}商品清单
              </div>
              <div class="store-btns">
                <!-- <div class="head-btn">商品首营资料</div>
                <div class="head-btn">企业首营资料</div>
                <div class="head-btn">下载全部报告单</div>
                <div class="head-btn">导出明细</div> -->
                <div v-if="(itemF.saleOrder.ckStatus === '1' || itemF.saleOrder.ckStatus === '3') && itemF.saleOrder.invoiceStatus !== '1' &&  itemF.saleOrder.invoiceStatus !== '2'" @click="applyInvoice(itemF)" class="head-btn">
                  开具电子发票
                </div>
              </div>
            </div>
            <div class="store-info-content">
              <div class="header">
                <span class="store-name">商品信息</span>
                <span style="width: 140px">单价</span>
                <span style="width: 140px; padding-left: 10px">数量</span>
                <span style="width: 140px">优惠金额</span>
                <span style="width: 120px">实付金额</span>
                <span style="width: 140px; text-align: center">操作</span>
              </div>
              <!-- 商品列表 -->
              <div class="content">
                <div class="shop-page">
                  <div class="commodity-box">
                    <div
                      class="li"
                      v-for="(item, index) of itemF.saleOrderItemList"
                      :key="'sales-goods-' + item.id"
                    >
                      <div class="center shop-info" style="width: 370px">
                        <div class="picture">
                          <div @click="routerProDetail(item)" class="el-image">
                            <img
                              v-lazy="productDataImg(item)"
                              class="el-image__inner"
                            />
                          </div>
                        </div>
                        <div class="commodity-content">
                          <div
                            @click="routerProDetail(item)"
                            class="trade-name"
                          >
                            {{ item.productName }}
                          </div>
                          <div class="specifications">
                            {{ item.productFormat }} {{ item.productUnit }}
                          </div>
                          <div class="company">
                            {{ item.productManufacturer }}
                          </div>

                          <div class="bottom">
                            <span>批准文号:</span>
                            <span style="margin-right: 6px">{{
                              item.productLicense
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="center danjia" style="width: 140px">
                        <span class="unit">¥{{ item.confirmPrice }} </span>
                        <span
                          v-if="item.updatePrice && item.updatePrice !== ''"
                          class="unit czj"
                          >储值价：￥{{ item.updatePrice }}
                        </span>
                      </div>
                      <div class="center shuliang" style="width: 140px">
                        <span class="unit amount-item">
                          x {{ item.saleAmount }}</span
                        >
                        <p
                          v-if="item.oosDealAmount > 0"
                          class="oos-amount amount-box"
                        >
                          异常数量：<span> {{ item.oosDealAmount }}</span>
                        </p>
                        <p
                          v-if="item.returnAmount > 0"
                          class="oos-amount amount-box"
                        >
                          已退数量：<span> {{ item.returnAmount }}</span>
                        </p>
                        <p
                          v-if="item.ckErrorInfo && item.ckErrorInfo !== ''"
                          class="oos-amount amount-box"
                        >
                          异常原因：<span> {{ item.ckErrorInfo }}</span>
                        </p>
                      </div>
                      <div class="center youhuijine" style="width: 140px">
                        <span class="unit"
                          >-¥{{
                            item.discountMoney ? item.discountMoney : "0.00"
                          }}</span
                        >
                      </div>
                      <div class="center shifujine" style="width: 120px">
                        <span class="unit">¥{{ item.saleMoney }} </span>
                        <p
                          v-if="item.oosDealMoney"
                          class="oos-amount amount-box"
                        >
                          ￥{{ item.oosDealMoney }}
                        </p>
                        <p
                          v-if="item.returnMoney"
                          class="oos-amount amount-box"
                        >
                          ￥{{ item.returnMoney }}
                        </p>
                      </div>
                      <div
                        class="center caozuo"
                        @click="jionOneGoodsCart(item)"
                        style="width: 140px"
                      >
                        <span> 加入购物车 </span>
                        <div v-if="false" class="other">
                          <div class="other-btn">预览</div>
                          <div class="line"></div>
                          <div class="other-btn">下载报告单</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品缺货列表 -->
          <div
            v-if="
              itemF.saleOrderItemOosList &&
              itemF.saleOrderItemOosList.length > 0
            "
            class="store-info"
          >
            <div class="store-info-head">
              <div class="title">商品缺货列表</div>
            </div>
            <div class="store-info-content">
              <div class="header">
                <span class="store-name">商品信息</span>
                <span style="width: 140px">单价</span>
                <span style="width: 100px">数量</span>
                <span style="width: 140px">优惠金额</span>
                <span style="width: 120px">缺货金额</span>
                <span style="width: 140px; text-align: center">操作</span>
              </div>
              <!-- 商品列表 -->
              <div class="content">
                <div class="shop-page">
                  <div class="commodity-box">
                    <div
                      class="li"
                      v-for="(item, index) of itemF.saleOrderItemOosList"
                      :key="'sales-goods-' + item.id"
                    >
                      <div class="center shop-info" style="width: 410px">
                        <div class="picture">
                          <div class="el-image">
                            <img
                              v-lazy="productDataImg(item)"
                              class="el-image__inner"
                            />
                          </div>
                        </div>
                        <div class="commodity-content">
                          <div class="trade-name">{{ item.productName }}</div>
                          <div class="specifications">
                            {{ item.productFormat }} {{ item.productUnit }}
                          </div>
                          <div class="company">
                            {{ item.productManufacturer }}
                          </div>

                          <div class="bottom">
                            <span>批准文号:</span>
                            <span style="margin-right: 6px">{{
                              item.productLicense
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="center danjia" style="width: 140px">
                        <span class="unit">¥{{ item.confirmPrice }} </span>
                      </div>
                      <div class="center shuliang" style="width: 100px">
                        <span class="unit"> x{{ item.oosAmount }}</span>
                      </div>
                      <div class="center youhuijine" style="width: 140px">
                        <span class="unit"
                          >-¥{{
                            item.discountMoney ? item.discountMoney : "0.00"
                          }}</span
                        >
                      </div>
                      <div class="center shifujine" style="width: 120px">
                        <span class="unit">¥{{ item.oosMoney }} </span>
                      </div>
                      <div
                        @click="shortageRegistration"
                        class="center caozuo"
                        style="width: 140px"
                      >
                        <span> 缺货登记 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="total-content">
          <div class="order-remark">订单备注：{{ saleOrder.remark }}</div>
          <div class="total-info">
            <div class="left flex">
              <span class="special">
                <!-- <span class="sr">总规格：1种</span> -->
                <span class="sr"
                  >订单商品品种：{{ saleOrderItemList.length }}个，</span
                >
                <span class="sl">商品原价</span>
              </span>
              <span class="sl">运费</span>
              <span class="sl">缺货金额</span>
              <span class="sl">优惠金额</span>
              <span class="sr">实付金额</span>
              <span class="sr">优惠券名称</span>
            </div>
            <div class="right flex">
              <span class="normal-price"
                >¥ {{ saleOrder.originalSaleMoney }}</span
              >
              <span class="normal-price"
                >¥
                {{
                  saleOrder.logisticsMoney ? saleOrder.logisticsMoney : "0.00"
                }}</span
              >
              <span class="normal-price">¥ {{ saleOrder.oosMoney }}</span>
              <span class="normal-price"
                >¥ {{ saleOrder.confirmDiscountMoney }}</span
              >
              <span class="other-price">¥ {{ saleOrder.payMoney }}</span>
              <span class="other-price">{{
                saleOrder.discountTicketName
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="false" class="store-info">
        <div class="store-info-head">
          <div class="title">发票信息</div>
        </div>
        <div class="order-detail-invoice-data">
          <div class="invoice-wapper">
            <div
              class="electronic-invoice-issuance-completed invoice-common-show"
            >
              <div class="invoice-common-item">
                <span>发票状态：</span
                ><span style="color: rgb(51, 51, 51)">已开</span>
              </div>
              <div class="invoice-common-item">
                <span>发票形式：</span><span>电票</span>
              </div>
              <div class="invoice-common-item">
                <span>发票金额：</span><span>¥808.65</span>
              </div>
              <div class="invoice-common-item last-invoice-common-item">
                <div class="invoice-label">发票号码：</div>
                <div
                  class="show-invoice-number"
                  style="display: flex; flex-wrap: wrap"
                >
                  <div>
                    76903462
                    <span style="display: none">,</span>
                  </div>
                </div>
              </div>
              <div
                class="invoice-common-item invoice-common-item-btn"
                style="min-width: auto"
              >
                <span size="small" type="text" class="el-button-no-view-invoice"
                  >查看发票
                  <i class="el-icon-arrow-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="bottomHover"></div>
      <div class="bottom-hover" :class="{ fixed: fixedStatus }">
        <div class="bottom-btns">
          <el-dropdown v-if="invoiceUrlList.length > 0" trigger="hover">
            <span class="el-dropdown-link head-btn-invoice">
              发票下载<i class="el-icon-arrow-up el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <a
                v-for="(item, index) of invoiceUrlList"
                :key="index"
                target="_blank"
                :href="item.url"
              >
                <el-dropdown-item>{{ item.name }}</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div class="head-btn">
            评价
          </div> -->
          <div
            v-if="saleOrder.nextFlow == 'pay'"
            @click="payOrderClick(saleOrder)"
            class="head-btn"
          >
            支付订单
          </div>
          <div
            v-if="
              saleOrder.feeType == 2 &&
              saleOrder.status == 2 &&
              saleOrder.payStatus == 1
            "
            @click="navToApplyReturn"
            class="head-btn"
          >
            申请售后
          </div>
          <div
            v-if="
              saleOrder.nextFlow == 'pay' && saleOrder.optCancelFlag === '1'
            "
            @click="gobackShoppingCart"
            class="head-btn"
          >
            返回购物车修改
          </div>
          <div v-else @click="confirmJionShop" class="head-btn">再次购买</div>
          <div
            v-if="saleOrder.optCancelFlag === '1'"
            @click="cencalOrder(saleOrder)"
            class="head-btn"
          >
            取消订单
          </div>
          <div v-if="false" class="head-btn">确认收货</div>
        </div>
      </div>
    </div>
    <logistics-info-modal ref="LogisticsInfo"></logistics-info-modal>
    <invoice-modal ref="invoiceModal"></invoice-modal>
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
import InvoiceModal from "../components/order/InvoiceModal.vue";
import LogisticsInfoModal from "../components/order/LogisticsInfoModal.vue";
export default {
  components: { LogisticsInfoModal, InvoiceModal },
  name: "OrderDetail",
  data() {
    return {
      saleOrderId: "",
      interestMoney: 0, // 优惠的金额
      saleOrder: {}, // 订单头部数据
      companySaleOrderList: {}, // 订单头部数据
      customerAddress: {}, // 订单头部数据
      saleOrderItemList: [], //创建的产品列表数据
      saleOrderItemOosList: [], // 缺货列表的数据
      checked: false,
      returnLogisticsStatus: 1,
      timers: null,
      scrollTop: 0,
      fixedStatus: false,
      companyId: "",
      invoiceUrlList: [], // 订单详情发票列表
      orderStatusType: 0, // 订单的状态 1:待确认 2.待支付
      discountTicketList: [], //订单使用优惠券列表数据
      selectedLogistics: null, // 选中的物流单数据
      selectedLogisticsWaybillId: "", // 选中的物流单号
    };
  },
  computed: {
    originalOosMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let originalConfirmMoney = this.saleOrder.originalConfirmMoney
        ? this.saleOrder.originalConfirmMoney
        : 0;
      let originalOosMoney = accAdd(originalSaleMoney, -originalConfirmMoney);
      return originalOosMoney;
    },
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    this.saleOrderId = saleOrderId;
    if (saleOrderId) {
      this.getOrderDeatil();
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    clearInterval(this.timers);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    applyInvoice(item) {
      console.log("当前申请的订单仓库数据为+", item);
      let invoiceData = {
        saleOrderId: item.id,
        companyId: item.companyId,
        invoiceMoney: item.saleOrder.payMoney,
      };
      this.$refs.invoiceModal.open(invoiceData);
    },
    routerProDetail(item) {
      let text = this.$router.resolve({
        name: "productDetail",
        query: { productId: item.productId },
      });
      window.open(text.href, "_blank");
    },
    // 查看物流详情
    showLogisticsInfo() {
      let selectedLogistics = this.selectedLogistics;
      console.log("selectedLogistics++++++++++",selectedLogistics)
      if (selectedLogistics.logisticsInfo && selectedLogistics.logisticsInfo !== "") {
        this.$refs.LogisticsInfo.open(selectedLogistics);
      } else {
        this.$message.error("暂无物流明细数据，请复制物流单号后前往官网查询！")
      }
    },
    // 选中指定物流的数据
    changeLogisticsWaybillId(item) {
      this.selectedLogisticsWaybillId = item.logisticsWaybillId;
      this.selectedLogistics = item;
    },
    // 切换仓库选择
    changeLogisticsStock(value, e) {
      console.log("companyId++++++", this.companyId);
      let companyId = this.companyId;
      // 选中的仓库的物流数据切换
      let stockLogistics = null;
      let companySaleOrderList = this.companySaleOrderList;
      for (let key of companySaleOrderList) {
        if (
          key.companyId === companyId &&
          key.logisticsVoList &&
          key.logisticsVoList.length > 0
        ) {
          this.selectedLogistics = key.logisticsVoList[0];
          this.selectedLogisticsWaybillId =
            key.logisticsVoList[0].logisticsWaybillId;
        }
      }
    },
    shortageRegistration() {
      this.$router.push({ path: "/wantToBuyProduct" });
    },
    payOrderClick(item) {
      this.$router.push({
        name: "orderPay",
        query: { payOrderId: item.payOrderId, saleOrderId: item.id },
      });
    },
    navToApplyReturn() {
      let saleOrderId = this.saleOrderId;
      // this.$router.push({
      //   name: "applyReturn",
      //   query: {
      //     saleOrderId: saleOrderId,
      //   },
      // });
      this.$router.push({
        name: "returnRefund",
        query: {
          saleOrderId: saleOrderId,
        },
      });
    },
    downloadInvoiceUrl(invoiceUrl) {
      window.open(invoiceUrl);
    },
    // 显示产品图片
    productDataImg(item) {
      if (item.productPic && item.productPic !== "") {
        let imgsArr = item.productPic.split(",");
        let imgSrc = this.$util.resizeImg(imgsArr[0], 200);
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    handleScroll() {
      const element = this.$refs.bottomHover; // 获取元素的DOM对象
      if (element) {
        const rect = element.getBoundingClientRect(); // 获取元素相关的位置信息
        const distanceToBottom = window.innerHeight - rect.bottom; // 计算距离页面底部的距离
        // console.log(distanceToBottom); // 输出距离页面底部的距离
        if (distanceToBottom < 0) {
          this.fixedStatus = true;
        } else {
          this.fixedStatus = false;
        }
      } else {
        // console.error('未能找到指定的元素');
      }
    },
    linkProDetail(item) {
      let text = this.$router.resolve({
        path: "productDetail",
        query: { productId: item.productId },
      });
      window.open(text.href, "_blank");
    },
    showCxInfo(cxInfo) {
      this.$alert(cxInfo ? cxInfo : "", "促销信息", {
        type: "info",
      });
    },
    // 展示商品缺货原因
    showErrorInfo(item) {
      this.$alert(
        item.errorInfo ? item.errorInfo : "缺货原因不明，请联系开票员！",
        "缺货原因",
        {
          type: "error",
        }
      );
    },
    // 展示商品缺货原因
    showCkErrorInfo(item) {
      this.$alert(
        item.ckErrorInfo ? item.ckErrorInfo : "异常原因不明，请联系开票员！",
        "异常原因",
        {
          type: "error",
        }
      );
    },
    gotoOrderList() {
      this.$router.push({
        name: "orderList",
        query: {
          state: 3,
        },
      });
    },
    productImg(item) {
      if (item.productPic && item.productPic !== "") {
        let picUrlArr = item.productPic.split(",");
        const imgsArr = picUrlArr[0];
        const imgSrc = this.$util.resizeImg(this.$imgPath + imgsArr, 80);
        return imgSrc;
      } else {
        const imgSrc = "~@/assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    // 查看订单批次效期
    lookProductBatchData(item) {
      let url = "/saleOrder/saleOrderItemBatch/listBySaleOrderItemId";
      let data = {
        saleOrderItemId: item.id,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          if (resData && resData.length > 0) {
            let batchData = res.result[0];
            let productBatchData = `批次：${batchData.batchId}<br>批号：${batchData.batchNumber}<br>生产日期：${batchData.productDate}<br>有效期：${batchData.expireDate}`;
            this.$alert(productBatchData, "产品批次信息：", {
              dangerouslyUseHTMLSdiving: divue,
            });
          } else {
            this.$alert("暂无批次效期数据！", "产品批次信息：", {
              type: "error",
            });
          }
        }
      });
    },
    // 订单申请展示的方式
    showStatus(saleOrder) {
      // 订单付款方式
      let feeType = saleOrder.feeType;
      // 订单付款状态
      let payStatus = saleOrder.payStatus;
      // 订单的创建状态
      let status = saleOrder.status;
      if (feeType === 2 && payStatus === 1 && status === 2) {
        return true;
      } else {
        return false;
      }
    },
    // 确认订单操作
    linkConfirmOrder(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "/confirmOrder",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 跳转支付操作
    linkPayOrder(item) {
      let payOrderId = item.payOrderId;
      let saleOrderId = item.id;
      this.$router.push({
        path: "/orderPay",
        query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
      });
    },
    // 获取订单详情数据
    getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          that.returnLogisticsStatus = resData.returnLogisticsStatus;
          that.saleOrder = resData.saleOrder;
          that.companySaleOrderList = resData.companySaleOrderList;
          if (
            resData.companySaleOrderList &&
            resData.companySaleOrderList.length > 0
          ) {
            let invoiceUrlList = [];
            let companySaleOrderList = resData.companySaleOrderList;
            for (let index in companySaleOrderList) {
              if (
                companySaleOrderList[index].saleOrder &&
                companySaleOrderList[index].saleOrder.invoiceUrl &&
                companySaleOrderList[index].saleOrder.invoiceUrl !== ""
              ) {
                let invoiceUrlArr =
                  companySaleOrderList[index].saleOrder.invoiceUrl.split(",");
                let companyName = companySaleOrderList[index].companyName;
                if (invoiceUrlArr.length > 1) {
                  for (let i in invoiceUrlArr) {
                    let invoiceOption = {
                      name: `${companyName}发票-${parseInt(i) + 1}`,
                      url: invoiceUrlArr[i],
                    };
                    invoiceUrlList.push(invoiceOption);
                  }
                } else {
                  let invoiceOption = {
                    name: `${companyName}发票`,
                    url: invoiceUrlArr[0],
                  };
                  invoiceUrlList.push(invoiceOption);
                }
              }
            }
            console.log("可下载的发票列表数据为", invoiceUrlList);
            this.invoiceUrlList = invoiceUrlList;
            this.companyId = resData.companySaleOrderList[0].companyId;
            let selectedLogisticsVoList =
              resData.companySaleOrderList[0].logisticsVoList;
            if (selectedLogisticsVoList && selectedLogisticsVoList.length > 0) {
              this.selectedLogisticsWaybillId =
                selectedLogisticsVoList[0].logisticsWaybillId;
              this.selectedLogistics = selectedLogisticsVoList[0];
            }
          }
          that.discountTicketList = resData.discountTicketList;
          that.customerAddress = resData.customerAddress;
          that.saleOrderItemList = resData.saleOrderItemList;
          that.saleOrderItemOosList = resData.saleOrderItemOosList;
        } else {
          that.$alert(res.message, '温馨提示', {
            type: 'error'
          })
        }
      });
    },
    // 订单状态
    orderStatus(item) {
      let status = item.status; // -1:已取消 0:草稿 1:待确认 2:已完成
      let feeType = item.feeType; // 付款类型1：线下2：线上
      let cancelFlag = item.cancelFlag; // 订单取消状态
      let payStatus = item.payStatus; //付款状态，0初始 1支付完成
      let ckStatus = item.ckStatus; // 出库状态 0 未出库 1.已出库 2.出库失败
      if (cancelFlag == 1) {
        return "已取消";
      } else if (status === 1) {
        this.orderStatusType = 1;
        return "待确认";
      } else if (status === 2) {
        if (feeType === 2 && payStatus === 0) {
          this.orderStatusType = 2;
          return "待支付";
        } else {
          return "已完成";
        }
      }
    },
    // 申请退货的操作
    applyForReturn(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "returnRequest",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 批量加入购物车提示操作
    confirmJionShop() {
      let _this = this;
      this.$confirm("是否将订单批量添加至购物车?", "确认提示:", {
        type: "success",
      })
        .then((res) => {
          _this.jionShoppingCart();
        })
        .catch((error) => {});
    },
    jionOneGoodsCart(item) {
      let _this = this;
      let data = {
        uid: item.inventoryUid,
        addPrice: item.confirmMoney,
        amount: item.confirmAmount,
      };
      let url = "/shopping/cart/addList";
      postAction(url, [data]).then((res) => {
        if (res.code === 200 && res.success) {
          this.$alert(res.message, "提示消息：", { type: "success" });
          _this.$router.push("/shoppingCart");
        }
      });
    },
    // 取消订单
    cencalOrder(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否取消该笔订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const url =
          "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
        postAction(url, null, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单取消成功",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            that.$router.push({ path: "/orderList", query: { state: 4 } });
          }
        });
      });
    },
    // 返回购物车修改
    gobackShoppingCart() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      this.shopLoading = true;
      // 1.先取消订单
      let url =
        "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
      postAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          // 2.返回购物车修改
          that.jionShoppingCart();
        } else {
          this.shopLoading = false;
          that.$message({
            message: res.message,
            offset: 400,
            duration: 3000,
            type: "error",
          });
        }
      });
    },
    // 批量添加购物车
    jionShoppingCart() {
      let _this = this;
      let saleOrderItemList = this.saleOrderItemList;
      let jionProList = [];
      for (let key of saleOrderItemList) {
        let data = {
          uid: key.inventoryUid,
          addPrice: key.confirmMoney,
          amount: key.confirmAmount,
        };
        jionProList.push(data);
      }
      let url = "/shopping/cart/addList";
      postAction(url, jionProList).then((res) => {
        if (res.code === 200 && res.success) {
          this.$alert(res.message, "提示消息：", { type: "success" });
          _this.$router.push("/shoppingCart");
        }
      });
    },
    // 申请退运费
    applyReturnLog() {
      let _this = this;
      this.$prompt("", "申请退运费原因:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        let saleOrder = _this.saleOrder;
        let saleOrderId = saleOrder.id;
        let payOrderId = saleOrder.payOrderId;
        let url = "/saleOrder/saleReturnOrder/add";
        let data = {
          saleReturnOrder: {
            saleOrderId: saleOrderId,
            payOrderId: payOrderId,
            type: 3,
            remark: value,
          },
        };
        postAction(url, data, divue).then((res) => {
          if (res.code === 200 && res.success) {
            _this.returnLogisticsStatus = 1;
            _this.$alert("申请退运费已提交，请耐心等待审核！", {
              type: "success",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/detail";
.tips {
  color: #585858 !important;
}
</style>